import { Pipe, PipeTransform } from '@angular/core';
import { Cms } from '@impact/data';

@Pipe({
  name: 'hasData'
})
export class HasDataPipe implements PipeTransform {

  transform(data: Cms.MegaNavigationSpotDto | null): boolean {
      return !!data?.headline || !!data?.text;
  }

}
